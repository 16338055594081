<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="load">
              <b-row>
                <b-col lg="12" sm="12">
                  <b-row>
                    <b-col>
                      <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                        {{ $i18n.locale === 'bn' ? item.training_title_bn : item.training_title }}
                        <p>{{ trainingStartDate }} {{ '-' }} {{ trainingEndDate }}</p>
                      </list-report-head>
                    </b-col>
                  </b-row>
                  <b-row>
                    <div class="col-md-12" v-for="(item,index) in formData" :key="index">
                      <b-table-simple class="evaluation-table" borderless style="width:100%">
                        <!-- <b-tr>
                          <b-td><div class="pt-0"><span style="font-weight: bold;">{{ $t('elearning_tim.training_date') }}</span> : {{ item.training_date | dateFormat  }}</div></b-td>
                          <b-td><div class="pb-0"><span style="font-weight: bold;">{{ $t('elearning_tim.evaluation') }}</span>: {{ $n(item.avg_mark) }}</div></b-td>
                        </b-tr> -->
                        <b-tr>
                          <b-td style="width:85%"><div class="pt-0"><span style="font-weight: bold;">{{$t('elearning_tpm.course_name')}}</span> : {{ $i18n.locale === 'bn' ? item.topics_session_bn : item.topics_session }}</div></b-td>
                          <b-td style="width:15%"><div class="pb-0 text-right"><span style="font-weight: bold;">{{ $t('elearning_tim.evaluation') }}</span>: {{ $n(item.avg_mark) }}</div></b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><div class="pb-0"><span style="font-weight: bold;">{{ $t('elearning_tim.trainer_name') }}</span>: {{ ($i18n.locale === 'bn' ? item.name_bn : item.name) + ', ' }} {{ getDesignationData(item, 'panel') + ', ' }} {{ getOrgData(item, 'panel') }}</div></b-td>
                          <b-td><div class="pb-0 text-right"><span style="font-weight: bold;">{{ $t('globalTrans.average') }}</span>: {{ $n(avgCalculation(item.avg_mark)) }}</div></b-td>
                        </b-tr>
                      </b-table-simple>
                      <hr style="margin-top: 5px;margin-bottom: 10px;"/>
                      <div class="d-flex" id="eval" v-for="(itm,index1) in item.evalution" :key="index1">
                        <div class="col-sm-6 pb-0">
                          <span>{{$n(index1+1)}}{{ '.' }} {{ $i18n.locale === 'bn' ? itm.trainer_evaluation_name_bn : itm.trainer_evaluation_name }}</span>
                        </div>
                        <div class="col-sm-6 pb-0">
                          <b-table-simple class="evaluation-table" bordered style="width:100%">
                            <b-tr class="text-center">
                              <b-td style="width: 60px;">
                                <span v-if="itm.avg_mark >= 1 && itm.avg_mark < 2"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                                <span v-else>{{ $i18n.locale === 'bn' ? '১' : '1' }}</span>
                              </b-td>
                              <b-td style="width: 60px;">
                                <span v-if="itm.avg_mark >= 2 && itm.avg_mark < 3"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                                <span v-else>{{ $i18n.locale === 'bn' ? '২' : '2' }}</span>
                              </b-td>
                              <b-td style="width: 60px;">
                                <span v-if="itm.avg_mark >= 3 && itm.avg_mark < 4"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                                <span v-else>{{ $i18n.locale === 'bn' ? '৩' : '3' }}</span>
                              </b-td>
                              <b-td style="width: 60px;">
                                <span v-if="itm.avg_mark >= 4 && itm.avg_mark < 5"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                                <span v-else>{{ $i18n.locale === 'bn' ? '৪' : '4' }}</span>
                              </b-td>
                              <b-td style="width: 60px;">
                                <span v-if="itm.avg_mark === 5"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                                <span v-else>{{ $i18n.locale === 'bn' ? '৫' : '5' }}</span>
                              </b-td>
                            </b-tr>
                          </b-table-simple>
                      </div>
                      </div>
                    </div>
                  </b-row>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
.evaluation-table td{
  padding: 0px !important;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import { traineeEvalutionEvaList } from '../../api/routes'

export default {
  components: {
    ListReportHead
  },
  props: ['item'],
  created () {
    if (this.item) {
      this.getEvaluationData()
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      load: false,
      formData: {
        id: '',
        name: '',
        email: '',
        avg_mark: 0
      },
      circularInfo: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingStartDate: '',
      trainingEndDate: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    monthList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'জানুয়ারি' : 'January',
          text_en: 'January',
          text_bn: 'জানুয়ারি'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'ফেব্রুয়ারি' : 'February',
          text_en: 'February',
          text_bn: 'ফেব্রুয়ারি'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'মার্চ' : 'March',
          text_en: 'March',
          text_bn: 'মার্চ'
        },
        {
          value: 4,
          text: this.$i18n.locale === 'bn' ? 'এপ্রিল' : 'April',
          text_en: 'April',
          text_bn: 'এপ্রিল'
        },
        {
          value: 5,
          text: this.$i18n.locale === 'bn' ? 'মে' : 'May',
          text_en: 'May',
          text_bn: 'মে'
        },
        {
          value: 6,
          text: this.$i18n.locale === 'bn' ? 'জুন' : 'June',
          text_en: 'June',
          text_bn: 'জুন'
        },
        {
          value: 7,
          text: this.$i18n.locale === 'bn' ? 'জুলাই' : 'July',
          text_en: 'July',
          text_bn: 'জুলাই'
        },
        {
          value: 8,
          text: this.$i18n.locale === 'bn' ? 'আগস্ট' : 'August',
          text_en: 'August',
          text_bn: 'আগস্ট'
        },
        {
          value: 9,
          text: this.$i18n.locale === 'bn' ? 'সেপ্টেম্বর' : 'September',
          text_en: 'September',
          text_bn: 'সেপ্টেম্বর'
        },
        {
          value: 10,
          text: this.$i18n.locale === 'bn' ? 'অক্টোবর' : 'October',
          text_en: 'October',
          text_bn: 'অক্টোবর'
        },
        {
          value: 11,
          text: this.$i18n.locale === 'bn' ? 'নভেম্বর' : 'November',
          text_en: 'November',
          text_bn: 'নভেম্বর'
        },
        {
          value: 12,
          text: this.$i18n.locale === 'bn' ? 'ডিসেম্বর' : 'December',
          text_en: 'December',
          text_bn: 'ডিসেম্বর'
        }
      ]
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    getDesignationData (item, type) {
      if (parseInt(item.not_here_designation) === 0 && parseInt(item.profession_type) === 1) {
        let desigObj
        if (type === 'admin') {
          desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === item.profession_designation_id)
        }
        if (type === 'panel') {
          desigObj = this.$store.state.ExternalUserIrrigation.commonObj.designationList.find(designation => designation.value === item.profession_designation_id)
        }
        if (typeof desigObj !== 'undefined') {
          return this.$i18n.locale === 'en' ? desigObj.text_en : desigObj.text_bn
        } else {
          return ''
        }
      } else {
        return this.$i18n.locale === 'en' ? item.profession_designation_en : item.profession_designation_bn
      }
    },
    getOrgData (item, type) {
      if (item.profession_org_id && parseInt(item.profession_type) === 1) {
        let orgObj
        if (type === 'admin') {
          orgObj = this.$store.state.commonObj.organizationProfileList.find(designation => designation.value === item.profession_org_id)
        }
        if (type === 'panel') {
          orgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(designation => designation.value === item.profession_org_id)
        }
        if (typeof orgObj !== 'undefined') {
          return this.$i18n.locale === 'en' ? orgObj.text_en : orgObj.text_bn
        } else {
          return ''
        }
      } else {
        return this.$i18n.locale === 'en' ? item.profession_other_org_name : item.profession_other_org_name_bn
      }
    },
    avgCalculation (data) {
      return parseFloat(data * 100 / 5).toFixed(2)
    },
    async getEvaluationData () {
      this.load = true
      const search = {
        circular_memo_no: this.item.circular_memo_no,
        batch_no: this.item.batch_no
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, traineeEvalutionEvaList, search)
      if (!result.success) {
        this.formData = []
      } else {
          this.formData = result.data
          this.circularInfo = result.circularInfo
          const startDate = this.circularInfo.training_start_date.split('-')
          const endDate = this.circularInfo.training_end_date.split('-')
          const sMonth = startDate[1]
          const eMonth = endDate[1]
          const sMonthName = this.monthList.find(doc => doc.value === parseInt(sMonth))
          const eMonthName = this.monthList.find(doc => doc.value === parseInt(eMonth))
          if (parseInt(startDate[2]) < 10) {
            this.trainingStartDate = this.$n(0) + this.$n(startDate[2], { useGrouping: false }) + ' ' + (this.currentLocale === 'en' ? sMonthName.text_en : sMonthName.text_bn) + ' ' + this.$n(startDate[0], { useGrouping: false })
          } else {
            this.trainingStartDate = this.$n(startDate[2], { useGrouping: false }) + ' ' + (this.currentLocale === 'en' ? sMonthName.text_en : sMonthName.text_bn) + ' ' + this.$n(startDate[0], { useGrouping: false })
          }
          if (parseInt(endDate[2]) < 10) {
            this.trainingEndDate = this.$n(0) + this.$n(endDate[2], { useGrouping: false }) + ' ' + (this.currentLocale === 'en' ? eMonthName.text_en : eMonthName.text_bn) + ' ' + this.$n(endDate[0], { useGrouping: false })
          } else {
            this.trainingEndDate = this.$n(endDate[2], { useGrouping: false }) + ' ' + (this.currentLocale === 'en' ? eMonthName.text_en : eMonthName.text_bn) + ' ' + this.$n(endDate[0], { useGrouping: false })
          }
      }
      this.load = false
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
  #eval{
    display: inline-block;
  }
</style>
